.Toolbar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #183c44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px 0 20px;
  box-sizing: border-box;
  z-index: 90;
  transition: transform 0.5s ease-out;
  transition: 0.5s ease-in;
  animation: slide-in 0.6s ease;
}

.Toolbar nav {
  height: 100%;
}

.Logo {
  height: 80%;
}

.Stacked {
  box-shadow: 0 1px 9px grey;
  height: 60px;
  transition: 0.5s ease-in;
}

.Hidden {
  transform: translateY(-100%);
}

@media (max-width: 702px) {
  .DesktopOnly {
    display: none;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
